import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, reactive, onMounted, nextTick } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import { useRouter } from 'vue-router';
import { addOrUpdateVisitorMove } from '@/api/administration';
import uploadApp from '@/components/uploadApp.vue';
import moment from 'moment';
import 'dayjs/locale/zh-cn';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
export default {
  components: {
    uploadApp
  },

  setup() {
    const formRef = ref();
    const formState = reactive({
      id: null,
      visitorName: null,
      visitorCompany: null,
      mobilePhone: null,
      vehicleInfo: null,
      startTime: null,
      endTime: null,
      visitReason: null,
      visitorIcon: null,
      receptUserName: null,
      receptWorkno: null,
      receptDepartemntName: null,
      visitorSource: 1,
      type: 1
    });
    const {
      checkName,
      checkPhone
    } = getFieldRules();
    const rules = {
      receptUserName: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      visitorName: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      mobilePhone: [{
        required: true,
        validator: checkPhone,
        trigger: 'blur'
      }],
      startTime: [{
        required: true,
        message: '访问期限开始时间不能为空',
        trigger: 'change'
      }],
      endTime: [{
        required: true,
        message: '访问期限结束时间不能为空',
        trigger: 'change'
      }],
      visitorCompany: [{
        required: true,
        message: '访客公司不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      visitReason: [{
        required: true,
        message: '访问事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      receptDepartemntName: [{
        required: true,
        message: '接待人部门不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      receptWorkno: [{
        required: true,
        message: '接待人工号不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    };
    const router = useRouter();
    const loading = ref(false); //提交

    const handleOk = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        if (new Date(formState.startTime) > new Date(formState.endTime)) {
          _message.error('访问开始时间不能大于访问结束时间');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        loading.value = true;
        let res = await addOrUpdateVisitorMove(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          router.push('/loadPage');
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
        loading.value = false;
      });
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const range = (start, end) => {
      const result = [];

      for (let i = start; i < end; i++) {
        result.push(i);
      }

      return result;
    };

    const disabledDateTime = dates => {
      let hours = moment().hours(); //0~23

      let minutes = moment().minutes(); //0~59

      let seconds = moment().seconds(); //0~59
      //当日只能选择当前时间之后的时间点

      if (dates) {
        if (moment(dates).date() === moment().date()) {
          if (moment(dates).hours() === moment().hours()) {
            if (moment(dates).minutes() === moment().minutes()) {
              return {
                disabledHours: () => range(0, hours),
                disabledMinutes: () => range(0, minutes + 1),
                disabledSeconds: () => range(0, seconds + 1)
              };
            } else {
              return {
                disabledHours: () => range(1, hours),
                disabledMinutes: () => range(0, minutes + 1)
              };
            }
          } else {
            return {
              disabledHours: () => range(0, hours)
            };
          }
        }
      }
    };

    const headerImgUrl = val => {
      console.log(val);
      formState.visitorIcon = val;
    };

    const setTime = reactive({
      selectableRange: '00:00:00 - 10:00:00',
      disabledDate: time => {
        console.log(time);
        return time && time <= moment().subtract(1, 'days');
      }
    });
    const disabledDateTimes = moment();
    /** 禁选小时：如果当前选择的日期和被禁选的日期为同一天，则进行小时的禁用 */

    const disabledHour = () => {
      if (moment(formState.startTime).date() === moment().date()) {
        return range(0, disabledDateTimes.hours());
      }
    };

    const disabledHour1 = () => {
      if (moment(formState.endTime).date() === moment().date()) {
        return range(0, disabledDateTimes.hours());
      }
    };
    /** 禁选分钟：如果当前选择的日期和时间，和被禁选的日期和时间为同一天同一小时，则进行分钟的禁用 */


    const disabledMinute = () => {
      if (moment(formState.startTime).date() === moment().date()) {
        if (moment(formState.startTime).hours() === moment().hours()) {
          return range(0, disabledDateTimes.minutes());
        }
      }
    };

    const disabledMinute1 = () => {
      if (moment(formState.endTime).date() === moment().date()) {
        if (moment(formState.endTime).hours() === moment().hours()) {
          return range(0, disabledDateTimes.minutes());
        }
      }
    };

    const disabledSeconds = () => {
      if (moment(formState.startTime).date() === moment().date()) {
        if (moment(formState.startTime).hours() === moment().hours()) {
          if (moment(formState.startTime).minutes() === moment().minutes()) {
            return range(0, disabledDateTimes.seconds());
          }
        }
      }
    };

    const disabledSeconds1 = () => {
      if (moment(formState.endTime).date() === moment().date()) {
        if (moment(formState.endTime).hours() === moment().hours()) {
          if (moment(formState.endTime).minutes() === moment().minutes()) {
            return range(0, disabledDateTimes.seconds());
          }
        }
      }
    };

    const disabledTime = new Date();

    const forbid = () => {
      nextTick(() => {
        let els = document.querySelectorAll('.el-date-picker__editor-wrap input');

        for (var i = 0; i <= els.length - 1; i++) {
          els[i].setAttribute('readonly', 'readonly');
        }
      });
    };

    return {
      formRef,
      formState,
      rules,
      loading,
      handleOk,
      headerImgUrl,
      disabledDate,
      disabledDateTime,
      setTime,
      disabledHour,
      disabledMinute,
      disabledSeconds,
      disabledHour1,
      disabledMinute1,
      disabledSeconds1,
      locale: zhCn,
      disabledTime,
      forbid
    };
  }

};