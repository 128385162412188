/*
 * 行政管理接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例

// 查询资产类别
export const selectAssetsType = (param) => {
  return axios.post(`/personnelapi/knAssetsType/selectAssetsType`, param);
}
// 分页查询资产列表
export const selectAssetsByPagination = (param) => {
  return axios.post(`/personnelapi/knAssets/selectAssetsByPagination`, param);
}
// 添加或修改资产类别
export const addOrUpdateAssetsType = (param) => {
  return axios.post(`/personnelapi/knAssetsType/addOrUpdateAssetsType`, param);
}
//删除资产类别
export const deleteAssetsType = (param) => {
  return axios.post(`/personnelapi/knAssetsType/deleteAssetsType`, param);
}
// 查询资产的存放物理位置
export const selectLocationByParameter = (param) => {
  return axios.post(`/personnelapi/knMaterialsLocation/selectLocationByParameter`, param);
}
// 查询项目下人员名称
export const selectEmployeeByParameter = (param) => {
  return axios.post(`/personnelapi/employee/selectEmployeeByParameter`, param);
}
//添加资产信息
export const insertAssets = (param) => {
  return axios.post(`/personnelapi/knAssets/insertAssets`, param);
}
//查询资产使用记录
export const selectAssetUsedByPagination = (param) => {
  return axios.post(`/personnelapi/knAssets/selectAssetUsedByPagination`, param);
}
//资产详情
export const selectAssetsById = (param) => {
  return axios.post(`/personnelapi/knAssets/selectAssetsById`, param);
}
//资产使用详情接口
export const selectAssetUsedById = (param) => {
  return axios.post(`/wlapi/knAssets/selectAssetUsedById`,param);
}
//资产维保详情接口
export const selectMaintenanceById = (param) => {
  return axios.get(`/wlapi/knAssets/selectMaintenanceById?id=${param}`);
}
//资产派发/归还接口
export const updateUseOrReturn = (param) => {
  return axios.post(`/personnelapi/knAssets/updateUseOrReturn`, param);
}
//资产维保接口
export const updateMaintenance = (param) => {
  return axios.post(`/personnelapi/knAssets/updateMaintenance`, param);
}
//修改资产信息
export const updateAssets = (param) => {
  return axios.post(`/personnelapi/knAssets/updateAssets`, param);
}
//分页查询车辆信息
export const selectVehicleByPagination = (param) => {
  return axios.post(`/personnelapi/knVehicle/selectVehicleByPagination`, param);
}
//添加或修改车辆信息
export const addOrUpdateVehicle = (param) => {
  return axios.post(`/personnelapi/knVehicle/addOrUpdateVehicle`, param);
}
//查询车辆详情
export const selectVehicleById = (param) => {
  return axios.get(`/personnelapi/knVehicle/selectVehicleById?id=${param}`);
}
//删除车辆信息
export const deleteVehicle = (param) => {
  return axios.post(`/personnelapi/knVehicle/deleteVehicle`, param);
}
//查询预约记录
export const selectVehicleAppointmentList = (param) => {
  return axios.post(`/personnelapi/knVehicleAppointment/selectVehicleAppointmentList`, param);
}
//添加预约用车信息
export const addVehicleAppointment = (param) => {
  return axios.post(`/personnelapi/knVehicleAppointment/addVehicleAppointment`, param);
}
//分页查询预约列表
export const selectAppointmentByPagination = (param) => {
  return axios.post(`/personnelapi/knVehicleAppointment/selectAppointmentByPagination`, param);
}
//取消预约接口
export const updateCancelAppointment = (param) => {
  return axios.post(`/personnelapi/knVehicleAppointment/updateCancelAppointment`, param);
}
//查询预约详情
export const selectVehicleAppointmentById = (param) => {
  return axios.get(`/personnelapi/knVehicleAppointment/selectVehicleAppointmentById?id=${param}`);
}
//预约审批接口
export const updateToApproval = (param) => {
  return axios.post(`/personnelapi/knVehicleAppointment/updateToApproval`, param);
}
//查询访客申请列表
export const selectVisitorByPagination = (param) => {
  return axios.post(`/personnelapi/knVisitor/selectVisitorByPagination`, param);
}
//添加或修改访客
export const addOrUpdateVisitor = (param) => {
  return axios.post(`/personnelapi/knVisitor/addOrUpdateVisitor`, param);
}
//删除访问
export const deleteVisitor = (param) => {
  return axios.post(`/personnelapi/knVisitor/deleteVisitor`, param);
}
// 访客签到/签出/确认
export const updateVisitorState = (param) => {
  return axios.post(`/personnelapi/knVisitor/updateVisitorState`, param);
}
// 访问详情
export const selectVisitorById = (param) => {
  return axios.post(`/personnelapi/knVisitor/selectVisitorById`, param);
}
// 审批访客申请
export const updateToVisitor = (param) => {
  return axios.post(`/personnelapi/knVisitor/updateToVisitor`, param);
}
// 分页查询访问记录
export const selectVisitorHisByPagination = (param) => {
  return axios.post(`/personnelapi/knVisitor/selectVisitorHisByPagination`, param);
}
// 添加或修改访客(移动端)
export const addOrUpdateVisitorMove = (param) => {
  return axios.post(`/personnelapi/knVisitor/addOrUpdateVisitorMove`, param);
}
// 删除资产信息
export const deleteAssets = (param) => {
  return axios.post(`/personnelapi/knAssets/deleteAssets`, param);
}