import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-13e7f75a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "upload-box"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "ant-upload-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loading_outlined = _resolveComponent("loading-outlined");

  const _component_plus_outlined = _resolveComponent("plus-outlined");

  const _component_a_upload = _resolveComponent("a-upload");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_upload, {
    "file-list": $setup.fileList,
    "onUpdate:file-list": _cache[0] || (_cache[0] = $event => $setup.fileList = $event),
    name: "files",
    "list-type": "picture-card",
    class: "avatar-uploader",
    "show-upload-list": false,
    action: "/personnelapi/HyVisitors/uploadBatchNoBlock",
    "before-upload": $setup.beforeUpload,
    onChange: $setup.handleChange,
    data: $setup.uploadData,
    headers: $setup.uploadHeaders
  }, {
    default: _withCtx(() => [$setup.imageUrl ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: $setup.imageUrl,
      alt: "files",
      style: {
        "width": "110px"
      }
    }, null, 8, _hoisted_2)) : (_openBlock(), _createElementBlock("div", _hoisted_3, [$setup.loading ? (_openBlock(), _createBlock(_component_loading_outlined, {
      key: 0
    })) : (_openBlock(), _createBlock(_component_plus_outlined, {
      key: 1
    })), _createElementVNode("div", _hoisted_4, _toDisplayString($props.uploadText), 1)]))]),
    _: 1
  }, 8, ["file-list", "before-upload", "onChange", "data", "headers"])]);
}